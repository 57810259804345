.group {
}

.standard {
  & :global(> div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-10 md:pb-15 lg:pb-20;
  }

  &:global(:not(.transparent)) {
    @apply pt-8 pb-[1px];
  }

  &:global(.transparent) {
    @apply mb-0;
  }

  &:global(.lime),
  &:global(.campaign-lime) {
    @apply bg-summer-green-50;
  }

  &:global(.green),
  &:global(.campaign-green) {
    @apply bg-green-50;
  }

  &:global(.campaign-light-lime) {
    @apply bg-campaign-light-lime-100;
  }

  &:global(.campaign-dark-lime) {
    @apply bg-summer-green-100;
  }

  &:global(.campaign-dark-green) {
    @apply bg-green-100;
  }

  &:global(.campaign-yellow) {
    @apply bg-campaign-yellow-100;
  }

  &:global(.white),
  &:global(.campaign-white) {
    @apply bg-white;
  }

  &:global(.campaign-cyan) {
    @apply bg-dark-cyan-100;
  }
}

.article {
  @apply bg-transparent !important;

  & :global(> .inner-wrapper) {
    @apply pt-6 rounded;
    & > div:last-of-type {
      @apply pb-[1px];
    }

    & > div:last-of-type {
      @apply pb-8 mb-0;
    }
  }

  &:global(.green .inner-wrapper) {
    @apply bg-green-50;
  }

  &:global(.lime .inner-wrapper) {
    @apply bg-summer-green-50;
  }

  &:global(.transparent .inner-wrapper) {
    @apply py-0;
  }
}
